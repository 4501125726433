import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  dateInFuture,
  dateInPast,
  formatDate,
  formatHours,
} from "../utils/data"

const EventsList = ({ events }) => (
  <div className="flex flex-wrap justify-center">
    {events.map(({ node }) => {
      const { date, event_type, name, image, location } = node.data

      return (
        <Link
          to={`/${node.uid}/`}
          className="px-4 md:max-w-4xl w-full block text-left mb-10 no-underline flex"
          key={name.text}
        >
          {image && (
            <Img
              fluid={{
                ...image.fluid,
              }}
              className="w-full flex-1"
              imgStyle={{ objectFit: "contain" }}
            />
          )}
          <div className="pl-4 flex-1">
            {event_type && <span className="text-sm">{event_type}</span>}
            <h4 className="py-4">{name.text}</h4>
            {location && <p>{location}</p>}
            <p>{formatDate(date)}</p>
            <p>{formatHours(date)}</p>
          </div>
        </Link>
      )
    })}
  </div>
)

const EventsPage = ({ data }) => {
  const events = data.allPrismicEvent.edges
  const pastEvents = events.filter(event => dateInPast(event.node.data.date))
  const futureEvents = events.filter(event =>
    dateInFuture(event.node.data.date)
  )

  return (
    <Layout>
      <SEO title="Events" />
      <div className="max-w-5xl mx-auto w-full">
        <div className="my-8 text-center">
          <h1 className="my-8">Events</h1>
        </div>
        <EventsList events={futureEvents} />
        {Boolean(pastEvents.length) && (
          <div>
            <h4 className="text-center my-4">Past Events</h4>
            <EventsList events={pastEvents} />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  {
    allPrismicEvent(sort: { fields: data___date, order: DESC }, limit: 10) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            date
            event_type
            location
            image {
              fluid(maxWidth: 1024) {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              html
            }
          }
        }
      }
    }
  }
`
